import React from "react";
import { Spinner as Loader } from "react-bootstrap";

export default function SpinnerComponent() {
  return (
    <div style={{display: "flex", justifyContent: "center", height: "80vh", alignItems: "center"}}>
        <Loader
          style={{color: "#e55353"}}
          animation="border"
        />
    </div>
  );
}
