import { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from "../contexts/firebase";
import jsCookie from "js-cookie";
import { changeLoggedInStatus } from '../utils/dashboardAPI';
import {useStateWithCallbackLazy} from 'use-state-with-callback';
import { fstore } from '../lib/Firebase';

export function useAuthListener() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('DASHBOARD_USER_AUTH')));
    const [role, setRole] = useState("");
    const { firebase } = useContext(FirebaseContext);

    useEffect(() => {
        const listener = firebase.auth().onAuthStateChanged((authUser) => {
            if (authUser) {
                localStorage.setItem('DASHBOARD_USER_AUTH', JSON.stringify(authUser));
                let emailID = authUser.email;
                setUser(authUser);
                async function changeLoggedInStatusFunction(emailID) {
                    let data = {
                        email: emailID,
                        loggedIn: true
                    }
                    await changeLoggedInStatus(data).then(res => {
                        
                    }).catch(e => {
                        console.log(e);
                    })
                }
                async function getRole(emailID) {
                    let roleFunc = await fstore.collection("supportUsers").doc(emailID).get();
                    let role = roleFunc.data();
                    if(role) {
                        setRole(role?.role);
                        sessionStorage.setItem("loggedIn", role?.loggedIn ? role?.loggedIn : false);
                    }
                    else {
                        setRole("");
                        sessionStorage.setItem("loggedIn", role?.loggedIn ? role?.loggedIn : false);
                    }
                }
                getRole(emailID);
                changeLoggedInStatusFunction(emailID);
            } else {
                localStorage.removeItem('DASHBOARD_USER_AUTH');
                setUser(null);
            }
        });

        return () => listener();
    }, [firebase]);

    return { user, role };
}

export function useCookieAuthListener() {
    const [cookie, setCookie] = useStateWithCallbackLazy(jsCookie.get('gipuserid'));

    useEffect(() => {
        if (jsCookie.get('gipuserid')) {
            setCookie(jsCookie.get('gipuserid'));
        } else {
            setCookie(null);
        }
    }, []);

    return { cookie, setCookie };
}
