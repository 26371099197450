export const Login = '/';
export const DASHBOARD="/dashboard";
export const PAST_ORDERS="/pastOrders";
export const QRCODE="/qrCode";
export const ORDER_LOGS="/orderLogs";
export const USER_MANAGEMENT="/userManagement";
export const BALANCE_AND_DUE_DATES="/balanceDueDates";
export const PAYOUT ="/payout";
export const REPORTS= "/reports"
export const REFUND="/refund"
export const PREORDERS = "/preOrders"

export const REGISTER = '/:productId/dashboard/register';
export const HOME = '/:productId/home';

export const ERROR404="/error/404";
export const ERROR500="/error/500";
export const PageRequestAccess="/error/requestaccess";