import Firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';
import * as firebaseui from 'firebaseui'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId
};

const firebaseConfigForQRCode = {
  apiKey: process.env.REACT_APP_QRCode_apiKey,
  authDomain: process.env.REACT_APP_QRCode_authDomain,
  projectId: process.env.REACT_APP_QRCode_projectId,
  storageBucket: process.env.REACT_APP_QRCode_storageBucket,
  messagingSenderId: process.env.REACT_APP_QRCode_messagingSenderId,
  appId: process.env.REACT_APP_QRCode_appId
}

const firebase = Firebase.initializeApp(firebaseConfig);

const fanalytics = firebase.analytics();
const fstore = firebase.firestore();
const fauth = firebase.auth();
const fLoginUi = new firebaseui.auth.AuthUI(Firebase.auth());

const firebaseQRCode = Firebase.initializeApp(firebaseConfigForQRCode, 'secondary');
const fStoreForQrCode = firebaseQRCode.firestore();

export const fgetUser = async () => {
  let token;
  try {
    if (fauth?.currentUser) {
      localStorage.setItem('DASHBOARD_USER_AUTH', JSON.stringify(fauth.currentUser));
      token = await fauth.currentUser.getIdToken();
      return token;
    } else {
      firebase.auth().onAuthStateChanged(async (authUser) => {
        if (authUser) {
          localStorage.setItem('DASHBOARD_USER_AUTH', JSON.stringify(authUser));
          token = await fauth.currentUser.getIdToken();
          return token;
        }
      });
    }
  } catch {
    console.log("Error occured while logging out user");
  }
};

export const fBaseLogOut = async () => {
  let isSuccess = false;
  try {
    await fauth.signOut();
    isSuccess = true;
  } catch {
    console.log("Error occured while logging out user");
  }
  return isSuccess;
}

export const getOrdersToday = async (location) => {
  var dayStart = new Date();
  dayStart.setHours(0, 0, 0, 0);

  return await getUserOrdersBetweenTimestamp(location, dayStart.getTime());
}

export const getUserOrdersBetweenTimestamp = async (location, startTimeStamp, endTimeStamp) => {
  let snapshot = fstore.collection('locations').doc(location).collection('orders')
    .where("created", ">=", startTimeStamp);
  if (endTimeStamp) {
    snapshot = snapshot.where("created", "<=", endTimeStamp);
  }
  snapshot = await snapshot.orderBy("created", "desc").get();
  return snapshot.docs.map(doc => {
    let order = doc.data()
    order.order_no = doc.id;
    return order;
  });
}

export const updateOrderStatusDashboard = async (order_no, status, reason) => {
  const ref = fstore.collection('locations').doc(order_no.split("-")[0]).collection('orders').doc(order_no);
  const updateBody = {};
  updateBody[`meta.current_status`] = status;
  updateBody[`meta.status_reason`] = reason ? reason : '';
  await ref.update(updateBody);
  return true;
}

export { firebase, fanalytics, fstore, fauth, fLoginUi,
  firebaseQRCode, fStoreForQrCode
}