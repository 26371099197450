import axios from "axios";
import jsCookie from "js-cookie";
import TriggerNotification from "../Components/Notification/Notification";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers = {
    'Content-Type': 'application/json',
    'gipuserid': jsCookie.get('gipuserid')
}

const handleError = (error) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        let displayMsg = null;
        if (error.response.message) {
            displayMsg = error.response.message;
        } else if (error.response.data) {
            if (error.response.data.message) {
                displayMsg = error.response.data.message;
            } else if (error.response.data.errors && error.response.data.errors[0]) {
                displayMsg = error.response.data.errors[0].error;
            }
        } else if (error.response.data.error) {
            displayMsg = error.response.data.error;
        } else if (error.response.statusText) {
            displayMsg = error.response.statusText;
        }
        TriggerNotification('error', displayMsg, displayMsg);
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        TriggerNotification('error', error.request.status === 0
        ? 'Please check your internet connection!'
        : 'Something went wrong. Try again', 'connection-error');
    } else {
        // Something happened in setting up the request that triggered an Error
        TriggerNotification('error', error.message, error.message);
        console.log('Error', error.message);
    }
    console.log(error.config);
}

function GET(url,headers=null) {
    if (url.includes("support")) {
        axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
    } else {
        axios.defaults.baseURL = process.env.REACT_APP_NEW_API_BASE_URL;
    }

    axios.defaults.headers['gipuserid'] = jsCookie.get('gipuserid');
    if(headers&&headers.Authorization){
        axios.defaults.headers['Authorization'] = headers.Authorization;
    }
    return new Promise((resolve, reject) => {
        axios.get(url)
            .then(response => resolve(response))
            .catch(function (error) {
                handleError(error);
                reject(error);
            });
    })
}
function POST(url, data = {}, headers = null) {
    if (url.includes("support")) {
        axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
    } else {
        axios.defaults.baseURL = process.env.REACT_APP_NEW_API_BASE_URL;
    }

    return new Promise((resolve, reject) => {
        // convert to string if it's object
        try {
            if (typeof data !== 'string') {
                data = JSON.stringify(data);
            }
        } catch (error) {
            reject(error);
        }

        if (headers && headers.Authorization) {
            axios.defaults.headers['Authorization'] = headers.Authorization;
        }
        axios.defaults.headers['gipuserid'] = jsCookie.get('gipuserid');
        axios.post(url, data, headers)
            .then(response => resolve(response))
            .catch((error) => {
                handleError(error);
                reject(error);
            })
    })
}
function DELETE(url,headers=null) {
    if (url.includes("support")) {
        axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
    } else {
        axios.defaults.baseURL = process.env.REACT_APP_NEW_API_BASE_URL;
    }

    axios.defaults.headers['gipuserid'] = jsCookie.get('gipuserid');
    if(headers&&headers.Authorization){
        axios.defaults.headers['Authorization'] = headers.Authorization;
    }
    return new Promise((resolve, reject) => {
        axios.delete(url)
            .then(response => resolve(response))
            .catch((error) => {
                handleError(error);
                reject(error);
            })
    })
}
function PUT(url, data, headers = null) {
    if (url.includes("support")) {
        axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
    } else {
        axios.defaults.baseURL = process.env.REACT_APP_NEW_API_BASE_URL;
    }
    
    return new Promise((resolve, reject) => {
        // convert to string if it's object
        try {
            if (typeof data !== 'string') {
                data = JSON.stringify(data);
            }
        } catch (error) {
            reject(error);
        }

        if (headers && headers.Authorization) {
            axios.defaults.headers['Authorization'] = headers.Authorization;
        }
        axios.defaults.headers['gipuserid'] = jsCookie.get('gipuserid');
        axios.put(url, data,headers)
            .then(response => resolve(response))
            .catch((error) => {
                handleError(error);
                reject(error);
            })
    })
}
export { GET, POST, DELETE, PUT };