import { Notification } from '../../helpers/notification';

const TriggerNotification = (type, notificationMesssage, notificationToastId) => {
    Notification({
        type: type,
        displayMessage: notificationMesssage,
        toasterOptions: {
        toastId: notificationToastId,
        }
    });
}

export default TriggerNotification;