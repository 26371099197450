import React from 'react';
import { changeLoggedInStatus } from '../utils/dashboardAPI';

export default function usePersistedState(defaultValue,productId, key) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.sessionStorage.getItem(productId + "-" + key);
    return stickyValue && stickyValue !== null
      ? JSON.parse(stickyValue)
      : defaultValue;
  });
  React.useEffect(() => {
    if(key){
      window.sessionStorage.setItem(productId + "-" + key, JSON.stringify(value));
    }
  }, [key, value]);
  return [value, setValue];
}

export const useDashboardState = (defaultValue,key) => {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.sessionStorage.getItem(key);
    return stickyValue && stickyValue !== null
      ? JSON.parse(stickyValue)
      : defaultValue;
  });
  React.useEffect(() => {

    // helper function to change user loggedIn status
    function changeUserLoggedInStatus () {
      let emailID = JSON.parse(localStorage.getItem("DASHBOARD_USER_AUTH"))?.email;
      sessionStorage.removeItem("loggedIn");
      async function changeLoggedInStatusFunction(emailID) {
        let data = {
            email: emailID,
            loggedIn: false
        }
        await changeLoggedInStatus(data).then(res => {
            
        }).catch(e => {
            console.log(e);
        })
      }
      if (emailID)
        changeLoggedInStatusFunction(emailID);
    }

    window.onunload = function () {
      sessionStorage.removeItem(key);
      changeUserLoggedInStatus();
    }

    window.onbeforeunload = function () {
      changeUserLoggedInStatus();
    }
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}

export const usePersistedLocalStorageState = (defaultValue,productId, key) => {
  window.onloadstart = function () {
    localStorage.removeItem(key);
  }
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.localStorage.getItem(productId + "-" + key);
    return stickyValue && stickyValue !== null
      ? JSON.parse(stickyValue)
      : defaultValue;
  });
  React.useEffect(() => {
    window.onunload = function () {
      localStorage.removeItem(key);
    }
    if(key){
      window.localStorage.setItem(productId + "-" + key, JSON.stringify(value));
    }
  }, [key, value]);
  return [value, setValue];
}