import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { firebase } from "./lib/Firebase";
import { FirebaseContext } from './contexts/firebase';

import { icons } from './assets/icons';

import { Provider } from 'react-redux';
import store from './store';
import 'normalize.css';
import { GlobalStyles } from './global-styles';
import reportWebVitals from './reportWebVitals';

import { OrderInfoContext , ToggleInfoContext} from './contexts/ordersContext';

React.icons = icons

ReactDOM.render(
        <FirebaseContext.Provider value={{ firebase }}>
            <Provider store={store}>
                <GlobalStyles />
                <ToggleInfoContext>
                <OrderInfoContext>
                <App/>
                </OrderInfoContext>
                </ToggleInfoContext>
            </Provider>
        </FirebaseContext.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
