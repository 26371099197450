import {createContext, useContext, useRef, useState} from 'react';
import { useDashboardState } from '../helpers/persistent';

export const OrderInfo = createContext([]);

export const OrderInfoContext = ({ children}) => {
    const [details, setDetails] = useDashboardState([],"orders");
    const baseURL = useRef("");
    const finalURL = useRef("");
    const orderLogData = useRef([])
    return (
        <OrderInfo.Provider value={{details, setDetails, baseURL, finalURL,orderLogData}}>
            {children}
        </OrderInfo.Provider>
    )
}


export const useOrderInfoContext = () => {
    const {details, setDetails, baseURL, finalURL, orderLogData} = useContext(OrderInfo);
    return {details, setDetails, baseURL, finalURL, orderLogData}
}



export const toggleInfo = createContext(false);

export const ToggleInfoContext = ({ children}) => {
    const [toggled, setToggled] = useDashboardState(true, "toggled");

    return (
        <toggleInfo.Provider value={{toggled, setToggled}}>
            {children}
        </toggleInfo.Provider>
    )
}


export const useToggleInfoContext = () => {
    const {toggled, setToggled} = useContext(toggleInfo);
    return{toggled, setToggled}
}
